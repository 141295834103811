import { Component, Prop, Vue } from 'vue-property-decorator'

//warppets
import { 
  wIcon
} from '@/components/wrappers';

@Component({
	components: {
    wIcon
  }
})
export default class Breadcrumb extends Vue {
  @Prop() list!: Array<object>


  getTo(item: any){
    if( item.to == undefined ){
      return '#';
    }
    return item.to
  }

  teste(item: any){
    if( item.to != undefined ){
      if ( item.to < 0 ) {
        this.$router.go(item.to);
      }
      else{
        this.$router.push(item.to);
      }
    }
  }
}
