import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
//models
import { Mask } from './mask.mold'
import { Input, Form } from '../molds/Form'
//extra plugins
import { maska } from 'maska'

@Component({ directives: { maska } })
export default class WForm extends Vue {
	@Prop() onForm!: boolean
	@Prop() clear!: boolean
	@Prop({ required: true }) form!: Form

	form_!: Form
	validForm = true
	inputsForm!: Input[]
	classNameColumns!: string
	classNameColumn!: string

	constructor() {
		super();
		this.initForm()
	}

	mounted(): void {
		if(this.clear){
			this.clearForm()
		}
	}

	initForm(): void {
		this.form_ = this.form
		this.inputsForm = this.getForms()
		//verifica se deve indentar os campos
		this.setClassNameColumns()
	}

	@Watch('form') changeForm() {
		this.initForm()
	}

	@Watch('clear') changeClear(newValue: boolean) {
		if (newValue == true) {
			this.clearForm()
		}
	}

	checkForm(event: Event){		
		event.preventDefault()
		if (this.validateForm()) {
			//dispara o evento e envia um json com os valores
			//verifica o formato de saida
			if(this.form_.paramQuery){
				this.$emit("dispatch", this.getParamQuery())
			}else{
				this.$emit("dispatch", this.getJsonForm())
			}
		}else {
			//reseta validform
			this.validForm = true
			this.$forceUpdate();
		}
	}

	// retorna a mascara caso tenha, e configura o input
	configMask(input: Input): string | string[] {
		if (this.isEmpty(input.mask || '')) {
			//pega as configurações da mascara
			const mask: Mask = this.getMask(input.type.toLowerCase())
			//verifica se mascara existe
			if (mask.mold != 'none') {
				//verifica se deve configurar o label
				if (input.label != undefined) {
					input.label = mask.label
				}
				//configura o input
				input.placeholder = mask.label
				input.maxlength = mask.maxlength
				return mask.mold
			}
		}
		return input.mask || ''
	}

	getMask(type: string): Mask {
		switch (type) {
			case "rg":
				return {
					label: "RG",
					mold: ["##.###.###-#"],
					maxlength: 12
				}
			case "cpf":
				return {
					label: "CPF",
					mold: "###.###.###-##",
					maxlength: 14
				}
			case "document":
				return {
					label: "Documento",
					mold: ["##.###.###-#", "###.###.###-##", "##.###.###/####-##"],
					maxlength: 18
				}
			case "cel":
				return {
					label: "Telefone Celular",
					mold: "(##) #####-####",
					maxlength: 15
				}
			case "tel":
				return {
					label: "Telefone Fixo",
					mold: "(##) ####-####",
					maxlength: 14
				}
			case "cep":
				return {
					label: "CEP",
					mold: "#####-###",
					maxlength: 9
				}
			case "cnpj":
				return {
					label: "CNPJ",
					mold: "##.###.###/####-##",
					maxlength: 18
				}
		}
		return { label: "none", mold: "none", maxlength: 0 }
	}

	//verifica se deve indentar os campos em colunas
	setClassNameColumns(): void {
		if (this.form.indent === false) {
			this.classNameColumns = ""
			this.classNameColumn = ""
		} else {
			this.classNameColumns = "columns"
			this.classNameColumn = "column"
		}
	}

	getForms(): Input[] {
		const arrayInputs: Input[] = []

		this.form.grid.forEach(inputs => {
			inputs.forEach(input => {
				arrayInputs.push(input)
			})
		});

		return arrayInputs
	}

	validateForm(): boolean {
		//valida valores dentro dos inputs
		this.validateFields()
		return this.validForm;
	}

	getParamQuery(): string {
		let data = "?"
		//tratando campo data
		this.inputsForm.forEach(input => {
			if(!this.isEmpty(input.value)){
				//verifica se deve coverter a data
				if (input.type.toLowerCase() == "date"
						&& this.form_.convertDate) {
							data += `${input.fieldName}=${this.convertData(input.value)}&`
				} else {
					let value
					//verifica se tem mascara, se sim, remover characteres especiais
					if(input.isMask){
						value = this.removeSpecialCharactersAndSpaces(input.value)
					}else{
						value = input.value
					}
					//verifica se é parametro do tipo like
					if(input.like){
						value = `%${value}%`
					}
					data += `${input.fieldName}=${value || ''}&`
				}
			}
		})
		// if(data == "?"){
		// 	return ""
		// }
		// removendo "&" no final da string 
		data = data.replace(/&\s*$/, "");

		return data
	}

	parseDateUSA(date: Date): string {		
		const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
		const month = date.getMonth() < 10 ? `0${date.getMonth()+1}` : date.getMonth()+1
		const year = date.getFullYear();
		return `${year}-${month}-${day}`
	}

	parseDatePtBR(date: Date): string {
		const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
		const month = date.getMonth() < 10 ? `0${date.getMonth()+1}` : date.getMonth()+1
		const year = date.getFullYear();
		return `${day}/${month}/${year}`
	}

	getJsonForm(): Record<string, string> {
		let data = "{"
		//tratando campo data
		this.inputsForm.forEach(input => {
			//verifica se deve coverter a data
			if (input.type.toLowerCase() == "date"
					&& this.form_.convertDate) {
						data += `"${input.fieldName}": "${this.convertData(input.value)}",`
			}
			
			else if(input.type.toLowerCase() == "datepicker"){
				let date
				if(this.form_.convertDate){
					date = this.parseDatePtBR(input.value)
				}else{
					date = this.parseDateUSA(input.value)
				}
				data += `"${input.fieldName}": "${date}",`
			}
			
			else {
				//verifica se tem mascara, se sim, remover characteres especiais
				if(input.isMask){
					data += `"${input.fieldName}": "${this.removeSpecialCharactersAndSpaces(input.value)}",`
				}else{
					data += `"${input.fieldName}": "${input.value || ''}",`
				}
			}
		})
		// removendo a ultima virgula para converter para json
		data = data.replace(/,\s*$/, "");
		data += "}"
		const json = JSON.parse(data);

		return json
	}

	removeSpecialCharactersAndSpaces(value: string): string {
		return value.replace(/[^\w\s]/gi, '').replace(' ', '')
	}

	convertData(date: string): string {
		if (this.isEmpty(date)) {
			return ''
		} else {
			const year = date.split("-")[0]
			const month = date.split("-")[1]
			const day = date.split("-")[2]
			const convert = ("0" + day).slice(-2) + '/' + ("0" + month).slice(-2) + '/' + year
			return convert
		}
	}

	convertDataUsa(date: string): string {
		const year = date.split("/")[0]
		const month = date.split("/")[1]
		const day = date.split("/")[2]
		const convert = year + '-' +("0" + month).slice(-2) + '-' + ("0" + day).slice(-2) 
		return convert
	}

	//Cor da mensagem a exibir
	getLabelType(input: Input): string {
		if(input.isValid != undefined){
			if (input.required) {
				if (input.isValid) {
					return 'is-success'
				}
				return 'is-danger'
			}else{
				if(input.isValid == false){
					return 'is-danger'
				}
			}
		}
		return ''
	}

	getMessage(input: Input): string {
		if(input.isValid != undefined){
			if (input.isValid) {
				if (input.messageSuccess != undefined) {
					return input.messageSuccess
				}
			}else{
				if (input.messageDanger != undefined) {
					return input.messageDanger
				} return 'Campo inválido'
			}
		}
		return ''
	}
	//Validação dos campos de acordo com o tipo
	validateTypes(input: Input): boolean {
		switch (input.type.toLocaleLowerCase()) {
			case 'email':
				return this.isEmail(input.value)
			case 'number':
				return this.isNumber(input.value)
			case 'datepicker':
				return this.isDate(input.value)
		}
		
		//valida a mascara
		const mask: Mask = this.getMask(input.type)
		if (mask.mold != 'none') {
			//marca no input que ele tem mascara
			input.isMask = true
			//verifica se é documento
			if (mask.label == 'Documento') {
				const document = this.removeSpecialCharactersAndSpaces(input.value);				
				//verifica se o valor é igual a 9(rg) ou 11(cpf) ou 14(cnpj)
				if (document.length == 9){
					return true
				}else if(document.length == 11){
					return this.isCpf(document);
				}else if(document.length == 14){
					return this.isCNPJ(document)
				}else{
					return false
				}
			} 
		}
		return true
	}

	isDate(date: Date): boolean {		
		if(date.getFullYear()){
			return true
		} return false
	}

	validateFields(): void {
		this.inputsForm.forEach(input => {
			if (input.required) {
				if (input.type.toLocaleLowerCase() != "datepicker"
						&& this.isEmpty(input.value)
				) {
					input.isValid = false
				} else {
					input.isValid = this.validateTypes(input)
				}
			} else {
				if (!this.isEmpty(input.value)) {
					input.isValid = this.validateTypes(input)
				}else{
					input.isValid = true
				}
			}

			if(input.isValid == false){
				this.validForm = false
			}

		});

	}

	isEmail(value: string): boolean {
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(value).toLowerCase());
		// return /^[\w+.]+@\w+\.\w{2,}(?:\.\w{2})?$/.test(value)
	}

	isNumber(value: string): boolean {
		return !isNaN(parseInt(value))
	}

	isEmpty(value: string): boolean {
		if (value) {
			return (value.length === 0 || !value.trim());
		} return true
	}

	recoveryValue(value: string): any {
		if (value != undefined) {
			if (this.isNumber(value)) {
				return parseInt(value)
			} else if (value.toLowerCase() == 'true' || value.toLowerCase() == 'false') {
				if (value == 'true') {
					return true
				} return false
			}
		}
		return value
	}

	clearForm(): void {
		let oldRequired: boolean | undefined = false

		this.inputsForm.forEach(input => {
			oldRequired = input.required
			input.required = false
			if (input.type.toLowerCase() == 'select') {
				if (input.options != undefined) {
					input.value = input.options[0].value
				}
			} else {
				input.value = ''
			}
			input.required = oldRequired
		})
	}

	isCpf(cpf: string) {
		
		if (cpf.length !== 11 || ['00000000000', '11111111111', '22222222222',
			'33333333333', '44444444444', '55555555555', '66666666666',
			'77777777777', '88888888888', '99999999999'].includes(cpf)) {
			return false;
		}

		let soma = 0;
		for (let i = 0; i < 9; i++) {
			soma += parseInt(cpf.charAt(i)) * (10 - i);
		}
		let resto = 11 - (soma % 11);
		if (resto == 10 || resto == 11) {
			resto = 0;
		}
		if (resto != parseInt(cpf.charAt(9))) {
			return false;
		}
		soma = 0;
		for (let i = 0; i < 10; i++) {
			soma += parseInt(cpf.charAt(i)) * (11 - i);
		}
		resto = 11 - (soma % 11);
		if (resto == 10 || resto == 11) {
			resto = 0;
		}
		if (resto != parseInt(cpf.charAt(10))) {
			return false;
		}
		return true;
	}

	isCNPJ(cnpj: string) {
		//let cnpj = value;
		const valida = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
		let dig1 = 0;
		let dig2 = 0;

		//cnpj = cnpj.toString().replace(".", "").replace("/", "").replace("-", "");
		const digito = (parseInt(cnpj.charAt(12) + cnpj.charAt(13)));

		for (let i = 0; i < valida.length; i++) {
			if(i > 0 ){
				dig1 += parseInt(cnpj.charAt(i - 1)) * valida[i]
			}
			dig2 += parseInt(cnpj.charAt(i)) * valida[i];
		}
		dig1 = (((dig1 % 11) < 2) ? 0 : (11 - (dig1 % 11)));
		dig2 = (((dig2 % 11) < 2) ? 0 : (11 - (dig2 % 11)));

		if (((dig1 * 10) + dig2) != digito){
			return false
		}

		return true

	}

}
