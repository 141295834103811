
export const links = {
  prefeito: { slug: 'prefeito' },
  assessoriaGeral: { slug: 'assessoria-geral' },
  gabinetePrefeito: { slug: 'chefe-de-gabinete-do-prefeito' },
  conselhoSaude: { slug: 'conselho-municipal-da-saude' },
  conselhoAssisSocial: { slug: 'conselho-municipal-de-assitencia-social' },
  conselhoTutelar: { slug: 'conselho-tutelar' },
  cultura: { slug: 'cultura' },
  economia: { slug: 'economia' },
  esporte: { slug: 'esporte' },
  estruturaOrganizacional: { slug: 'estrutura-organizacional' },
  exPrefeitos: { slug: 'ex-prefeitos' },
  geografia: { slug: 'geografia' },
  historia: { slug: 'historia' },
  inicio: { slug: 'inicio' },
  localidades: { slug: 'localidades' },
  paginaExemplo: { slug: 'pagina-exemplo' },
  perguntasFrequentes: { slug: 'perguntas-frequentes' },
  procuradoriaGeral: { slug: 'procuradoria-geral' },
  secretariaSaude: { slug: 'secretaria-municipal-da-saude' },
  secretariaAdministracao: { slug: 'secretaria-municipal-de-administracao' },
  secretariaAgricultura: { slug: 'secretaria-municipal-de-agricultura' },
  secretariaAassistenciaSocial: { slug: 'secretaria-municipal-de-assistencia-social' },
  secretariaEsporte: { slug: 'secretaria-municipal-de-cultura-turismo-e-esporte' },
  secretariaEducacao: { slug: 'secretaria-municipal-de-educacao' },
  secretariaFinancas: { slug: 'secretaria-municipal-de-financas' },
  secretariaAmbiente: { slug: 'secretaria-municipal-de-meio-ambiente' },
  secretariaObras: { slug: 'secretaria-municipal-de-obras-e-servicos-urbanos' },
  simbolos: { slug: 'simbolos' },
  turismo: { slug: 'turismo' },
  comoChegar: { slug: 'como-chegar' },
  comoDeslocar: { slug: 'como-se-deslocar' },
  ondeComer: { slug: 'onde-comer' },
  vicePrefeito: { slug: 'vice-prefeito' },
}

export const subPanelListItens1 = [
  { title: 'Prefeito', slug: '/pagina/'+links.prefeito.slug },
  { title: 'Vice-Prefeito', slug: '/pagina/'+links.vicePrefeito.slug },
  { title: 'Estrutura Organizacional', slug: '/pagina/'+links.estruturaOrganizacional.slug },
  { title: 'Secretária Municipal da saúde', slug: '/pagina/'+links.secretariaSaude.slug },
  // { title: 'Comunicação', slug: '/pagina/'+links.prefeito.slug },
]

export const subPanelListItens2 = [
  { title: 'História', slug: '/pagina/'+links.historia.slug },
  { title: 'Geografia', slug: '/pagina/'+links.geografia.slug },
  { title: 'Economia', slug: '/pagina/'+links.economia.slug },
  { title: 'Simbolos', slug: '/pagina/'+links.simbolos.slug },
  { title: 'Localização', slug: '/pagina/'+links.localidades.slug },
  { title: 'Ex-Prefeitos', slug: '/pagina/'+links.exPrefeitos.slug },
]

// export const links = () => {
//   return 'https://aps.laranjadaterra.es.gov.br/api/core'
//   // return 'http://localhost:8088/conecta.php?url='+url;
// }

export default links;